/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
import moment from 'moment-timezone';
import 'moment';
import 'twix';
import './polyfills';
// eslint-disable-next-line import/no-internal-modules
import '@askyourteam-nz/react-library/dist/index.css';
// eslint-disable-next-line import/no-internal-modules
import { OAuthConfig } from '@askyourteam-nz/react-library/dist/lib';
import Log from '_Lib/Log';
import OAuth from '_Lib/OAuth';
import '../components/application.scss';

OAuthConfig({
  API_DOMAIN: () => {
    let port = window.location.port.length == 0 ? '' : (`:${window.location.port}`);
    return `${window.location.protocol}//${window.location.hostname}${port}`;
  },

  onInterceptFailed: error => {
    Log.breadcrumb('auth', `${error.response.status} - ${error.message}`);
    if (error.response.status === 401) {
      Log.breadcrumb('auth', 'Reloading page');
      OAuth.cleanCookies();
      window.history.pushState({}, undefined, '/login');
      window.location.reload();
    }
  },

  getAuthorization: () => `Bearer ${OAuth.token.access_token}`,
});

moment.locale(window.navigator.userLanguage || window.navigator.language);
